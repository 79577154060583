import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material/material.module';
import { MatChipsModule } from '@angular/material/chips';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ButtonComponent } from './components/button/button.component';
import { LogoComponent } from './components/logo/logo.component';
import { FilterAndSearchComponent } from './components/filter-and-search/filter-and-search.component';
import { AlertComponent } from './components/alert/alert.component';
import { ColorPillComponent } from './components/color-pill/color-pill.component';
import { KeywordInputComponent } from './components/keyword-input/keyword-input.component';
import { TableLoadingIndicatorComponent } from './components/table-loading-indicator/table-loading-indicator.component';
import { ModalWrapperComponent } from './components/modal-wrapper/modal-wrapper.component';
import { TableFilterHeaderComponent } from './components/table-filter-header/table-filter-header.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AppliedFiltersListingComponent } from './components/applied-filters-listing/applied-filters-listing.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { BooleanDisplayPipe } from './pipes/boolean-display.pipe';
import { GroupSelectComponent } from './components/group-select/group-select.component';
import { StartCasePipe } from './pipes/start-case-pipe';
import { FormsModule } from '@angular/forms';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TypographyComponent } from './components/typography/typography.component';
import { QualificationPickerComponent } from './qualifications/qualification-picker/qualification-picker.component';
import { QualificationPickerDialogComponent } from './qualifications/qualification-picker-dialog/qualification-picker-dialog.component';
import { UserWeightPipe } from './pipes/user-weight.pipe';
import { UserHeightPipe } from './pipes/user-height.pipe';
import { QualificationCardComponent } from './components/qualification-card/qualification-card.component';
import { QualificationStatusChipComponent } from './components/qualification-status-chip/qualification-status-chip.component';
import { QualificationAssignmentStatusPipe } from './pipes/qualification-assignment-status.pipe';
import {LocationSelectComponent} from "@shared/components/location-select/location-select.component";
import {TitleSelectComponent} from "@shared/components/title-select/title-select.component";
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { MultiSelectSearchComponent } from './components/multi-select-search/multi-select-search.component';
@NgModule({
  declarations: [
    AlertComponent,
    AppliedFiltersListingComponent,
    AvatarComponent,
    BooleanDisplayPipe,
    ButtonComponent,
    ColorPillComponent,
    ConfirmModalComponent,
    ConfirmModalComponent,
    DateRangeComponent,
    DateRangeComponent,
    FilterAndSearchComponent,
    GroupSelectComponent,
    KeywordInputComponent,
    LoadingButtonComponent,
    LoadingButtonComponent,
    LocationSelectComponent,
    LogoComponent,
    ModalWrapperComponent,
    ModalWrapperComponent,
    MultiSelectSearchComponent,
    NavbarComponent,
    QualificationCardComponent,
    QualificationPickerComponent,
    QualificationPickerDialogComponent,
    QualificationStatusChipComponent,
    QualificationAssignmentStatusPipe,
    SearchableSelectComponent,
    StartCasePipe,
    TableFilterHeaderComponent,
    TableFilterHeaderComponent,
    TableLoadingIndicatorComponent,
    TableLoadingIndicatorComponent,
    TitleSelectComponent,
    TypographyComponent,
    UserHeightPipe,
    UserWeightPipe,
  ],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, MatChipsModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [
    AlertComponent,
    AppliedFiltersListingComponent,
    AvatarComponent,
    BooleanDisplayPipe,
    ButtonComponent,
    ColorPillComponent,
    ConfirmModalComponent,
    DateRangeComponent,
    DateRangeComponent,
    FilterAndSearchComponent,
    FlexLayoutModule,
    GroupSelectComponent,
    KeywordInputComponent,
    LoadingButtonComponent,
    LoadingButtonComponent,
    LocationSelectComponent,
    LogoComponent,
    MaterialModule,
    ModalWrapperComponent,
    ModalWrapperComponent,
    MultiSelectSearchComponent,
    NavbarComponent,
    QualificationCardComponent,
    QualificationStatusChipComponent,
    QualificationAssignmentStatusPipe,
    SearchableSelectComponent,
    StartCasePipe,
    TableFilterHeaderComponent,
    TableFilterHeaderComponent,
    TableLoadingIndicatorComponent,
    TableLoadingIndicatorComponent,
    TitleSelectComponent,
    TypographyComponent,
    UserHeightPipe,
    UserWeightPipe,
  ],
})
export class SharedModule {}
